<template>
  <v-container fluid>
    <page-title title="Арт-терапия">
      <template #description>
        <v-card-text
          style="color: black; white-space: pre-line"
          :class="{ 'body-2': $vuetify.breakpoint.xs }"
          class=" headline text-justify body-1"
          ><span>
            Арт-практики и творческий процесс - лучший способ создания новых нейтронных связей и
            расширения мышления. Этот всегда приносит колоссальное эстетическое удовольствие и
            множество открытий. Создавая арт-объекты, взаимодействия с разными материалами и
            техниками, вы прикасаетесь к внутреннему миру, воплощаете мысли и идеи в образах, в
            ваших руках буквально рождаются новые миры и смыслы. Это дарит вдохновение, энергию и
            желание созидать, жить свою счастливую жизнь, находить для этого лучшие способы. А еще -
            веру в себя и смелость самовыражения. Программы этого раздела - это результат личных
            практик и приглашение в мир искусства. Мир, в котором мы на самом деле слышим себя. И
            нам это нравится.</span
          ></v-card-text
        >
      </template>
    </page-title>
    <!--    <select-author :list="selectAuthorList typeSelect === 'authors'/>-->
    <list-component :list="modifiedArtTherapyList" @createPath="createPath">
      <v-col key="a" class="cell-item" cols="12" sm="6" md="4">
        <v-card class="mx-auto" max-width="400">
          <v-img
            max-height="200px"
            src="https://firebasestorage.googleapis.com/v0/b/first-project-6daea.appspot.com/o/admin%2Fz-nejro-algor.jpg?alt=media&token=e7f27759-1412-493f-85ad-a0c67e3ffeb8"
          />
          <v-card-text class="text-justify">Отдельные видео-уроки нейрографики</v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text color="orange" to="/separateVideosList">
              Погрузиться
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col key="b" class="cell-item" cols="12" sm="6" md="4">
        <v-card class="mx-auto" max-width="400">
          <v-img max-height="200px" :src="consultationSrc" />
          <v-card-text class="text-justify"
            >Онлайн рисование нейрографики с Надеждой Навроцкой</v-card-text
          >
          <v-card-actions>
            <v-spacer />
            <v-btn text color="orange" to="/consultationBuy">
              Погрузиться
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </list-component>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'ArtTherapyList',
  metaInfo() {
    return {
      title: `Aрт-терапия Надежды Навроцкой`,
      titleTemplate: null,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: ``
        }
      ]
    }
  },
  components: {
    ListComponent: () => import('@/components/shared/lists/ListComponent'),
    PageTitle: () => import('@/components/shared/PageTitle')
    // SelectAuthor: () => import('@/components/shared/SelectAuthor')
  },
  data: () => ({
    consultationSrc:
      'https://firebasestorage.googleapis.com/v0/b/first-project-6daea.appspot.com/o/admin%2Fnejro-ind-500.jpg?alt=media&token=e5713359-fdb0-4c20-b6ec-86251301c6dd'
  }),
  computed: {
    ...mapState({
      user: state => state.user.user,
      listAuthors: state => state.shared.listAuthors
    }),
    ...mapGetters([
      'filterAllArtTherapyList',
      'filterAllListStudios',
      'filterAllListCalendars',
      'filterArtTherapyList',
      'filterListStudios',
      'filterListCalendars'
    ]),
    // selectAuthorList() {
    //   return [
    //     ...this.filterAllArtTherapyList.filter(studio => !studio.artNeurographics),
    //     ...this.filterAllListStudios.filter(studio => studio.secondType === 'artTherapy'),
    //     ...this.filterAllListCalendars.filter(calendar => calendar.secondType === 'artTherapy')
    //   ]
    // },
    modifiedArtTherapyList() {
      return [
        ...this.filterArtTherapyList.filter(studio => !studio.artNeurographics),
        ...this.filterListStudios.filter(studio => studio.secondType === 'artTherapy'),
        ...this.filterListCalendars.filter(calendar => calendar.secondType === 'artTherapy')
      ].map(studio => {
        const dadaAuthor = this.listAuthors.find(author => author.id === studio.author)
        return {
          ...studio,
          authorName: `${dadaAuthor.firstName} ${dadaAuthor.lastName}`
        }
      })
    }
  },
  created() {
    if (!this.filterAllListStudios.length) this.$store.dispatch('getListStudios', 'studios')
    if (!this.filterAllListCalendars.length) this.$store.dispatch('getListStudios', 'calendars')
    if (!this.filterAllArtTherapyList.length) this.$store.dispatch('getArtTherapy')
    if (!this.listAuthors.length) this.$store.dispatch('getListAuthors')
  },
  methods: {
    createPath(id) {
      let localItem = this.modifiedArtTherapyList.find(item => item.id === id)
      if (localItem.typeExercise === 'calendars') {
        this.$router.push(`/calendar/preview/${id}`)
      } else if (localItem.typeExercise === 'studios') {
        this.$router.push(`/studios/preview/${id}`)
      } else {
        this.$router.push(`/artTherapyDescription/${id}`)
      }
    }
  }
}
</script>

<style scoped />
